@import "variables";

.about-page-container {
  min-height: 100vh;
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: 1fr 1.25fr;
  padding: $site-padding;
  padding-bottom: 3rem;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
  @media (max-width: 1070px) {
    grid-gap: 3rem;
  }
  @media (max-width: 1070px) {
    //grid-gap: 4rem;
  }
  @media (max-width: 850px) {
    grid-template-columns: auto;
    grid-gap: 0;
    padding-bottom: 5rem;
  }
}

.about-page-left-image-container {
  padding-top: 3rem;
  align-items: flex-start;
}

.about-page-right-container {
  padding-top: 3rem;
  padding-right: 6rem;
  @media (max-width: 1200px) {
    padding-right: 5rem;
  }
  @media (max-width: 1000px) {
    padding-right: 3rem;
  }
  @media (max-width: 800px) {
    padding-right: 3rem;
  }
}

.about-page-two-column {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: auto;
    grid-gap: 2rem;
    margin-top: 2.5rem;
  }
}
