@import "variables.scss";

.navbar {
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: $navbar-size;
  //padding: $site-padding;
  padding-left: 20px;
  background-color: $navbar-color !important;
}

.navbar-brand {
  cursor: pointer;
  z-index: 5;
  width: 240px;
  margin-top: 0.5rem;
  @media (max-width: 450px) {
    width: 200px;
  }
  @media (max-width: 350px) {
    width: 170px;
  }
}

.navbar-item {
  font-family: $navbar-font;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25em;
  font-style: normal;
  text-transform: uppercase;
  margin: 0 7px;
  color: $navbar-font-color;
  display: inline;
  position: relative;
  .nav-item {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}

.navbar-item:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: $navbar-font-color;
  height: 1px;
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.navbar-item:hover:after,
.navbar-item:focus:after,
.navbar-item:active:after,
.navbar-item.active::after {
  left: 0;
  right: auto;
  width: 100%;
  .nav-link {
    &:hover,
    &.active {
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}

.navbar-right {
  z-index: 2;
}

.navbar-social {
  margin: 0 7px;
  display: inline-block;
  svg path {
    fill: $accent-color-bold;
  }
}

@media (min-width: 992px) {
  .navbar-center {
    padding-left: 75px;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .navbar-right {
    display: none;
  }
  .navbar-collapse {
    background-color: $navbar-color;
    box-shadow: 0px 10px 8px -8px #737373;
    //border-bottom: 1px solid #737373;
    border-top: 1px solid #e7e7e7;
    .nav-link {
      text-align: center;
    }
  }
}

.nav-placeholder {
  height: $navbar-size;
  width: 100%;
}
