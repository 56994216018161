@import "variables.scss";

.portfolio-wrapper {
  display: none;
  grid-template-columns: auto;
  grid-template-rows: auto 2rem auto 4rem;
  justify-items: center;
  @media (min-width: 1600px) {
    max-width: 1050px;
    margin: 0 auto;
  }
}

.instagram_gallery {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  @media (max-width: 1600px) {
    padding: 14rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1300px) {
    padding: 10rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 960px) {
    padding: 6rem;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 780px) {
    padding: 3rem;
    grid-gap: 25px;
    //grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding: 2rem;
    grid-gap: 0.75rem;
    //grid-template-columns: 1fr;
  }
  padding-bottom: 0 !important;
  padding-top: 2rem !important;
}

.instagram-image {
  width: 100% !important;
  margin: 0 !important;
  overflow: hidden;
  img {
    transition: 0.9s ease;
    &:hover {
      transform: scale(1.04);
    }
  }
}

.loading-spinner-wrapper {
  width: 100%;
  height: 100%;
  margin-top: -$navbar-size;
  display: flex;
  justify-content: center;
  align-items: center;
}
// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.instagram_username {
  font-family: "Cormorant Garamond", sans-serif;
}

.loading-spinner {
  transform: rotate(-135deg);
  font-family: Mulish, sans-serif;
  font-size: 15px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  visibility: inherit;
  line-height: 1px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  width: 100px;
  height: 100px;
  fill: none;
  opacity: 0.45;
  color: $light-gray-font;
  stroke: currentColor;
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03), color 0.01s ease-out;
  will-change: transform;
  animation: 2s linear infinite svg-animation;
  .loader-circle {
    display: block;
    fill: transparent;
    stroke: #2f3d4c;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 2px;
    transform-origin: 50% 50%;
    animation: 3s cubic-bezier(0.45, 0, 0.14, 1.03) infinite both
      circle-animation;
  }
}
