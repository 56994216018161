@import "../../node_modules/bootstrap/scss/bootstrap";
@import "variables.scss";
@import "navbar.scss";
@import "footer.scss";

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

*,
h1,
h2,
h3,
h4,
h5 {
  font-family: $header-font;
  box-sizing: border-box !important;
}

p {
  font-family: $body-font;
  color: $body-font-color;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6em;

  -webkit-font-smoothing: antialiased;
}

h1 {
  text-transform: uppercase;
  line-height: 50px;
  letter-spacing: 7px;
  font-weight: 300;
  font-size: 56px;
  color: $navbar-font-color;
  @media (max-width: 900px) {
    font-size: 48px;
  }
}

h2 {
  text-transform: uppercase;
  line-height: 1.3em;
  letter-spacing: 0.2em;
  font-weight: 300;
  font-size: 35px;
  color: $navbar-font-color;
}
h3 {
  position: relative;
  padding-bottom: 0;
  color: #4d504a;
  font-family: $header-font, serif;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
h4 {
  font-style: italic;
  letter-spacing: 0;
  font-family: $header-font;
  color: $header-font-color;
}

img {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    font-family: "Mulish", sans-serif;
    color: $body-font-color;
    font-weight: 300;
    line-height: 1.6em;
  }
}
.site-link {
  * {
    color: $accent-color-bold !important;
  }
  text-decoration: none;
  &:hover {
    color: $accent-color-bold !important;
    text-decoration: none;
  }
}

.body-container {
  .block {
    padding: $site-padding;
    margin: 25px 0;
  }
  .block:last-child {
    padding-bottom: $block-padding * 2;
  }
}

.row,
.col-sm-6,
.col {
  height: 100%;
}



.bg-gray {
  background-color: $background-gray;
}

.bg-accent-bold {
  background-color: $accent-color-bold;
}

.bg-white {
  background-color: $white;
}

.block {
  width: 100%;
  height: $block-height;
  padding: $block-padding;
  z-index: 5;
  overflow: hidden;
  position: relative;
  content-visibility: auto;
  @media (max-width: 900px) {
    height: auto;
  }
}

.adjustable-block {
  min-height: $block-height;
}

.dark-filter {
  filter: sepia(0.1) saturate(1.2) brightness(85%);
  -webkit-filter: sepia(0.1) saturate(1.2) brightness(85%);
}
.extra-dark-filter {
  filter: sepia(0.1) saturate(0.9) brightness(40%);
  -webkit-filter: sepia(0.1) saturate(0.9) brightness(40%);
}

.parallax-viewport {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  perspective: 1px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.parallax-image-header {
  z-index: 2;
  width: 100%;
  overflow: visible;
  transform: translateZ(-1px) scale(2) translateY(-15%);
}

.vert-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: column;
}

.primary-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.75em;
  font-weight: 500;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 12px 30px;
  cursor: pointer;
  color: #fff;
  background-color: $accent-color-light;
  border: 1px solid transparent;
  &:hover {
    box-shadow: 0 0 2px rgba(163, 163, 163, 0.99);
    background-color: $accent-color-bold-hover;
  }
}

.no-overflow {
  overflow: hidden;
}

.image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
}

.image-right {
  justify-content: flex-end;
}

.tiny-hr {
  width: 60px;
}
