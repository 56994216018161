@import "variables.scss";

.photography-header {
  margin: 3rem 0;
}

.photography-container {
  max-width: 1200px;
}

.photography-card-grid {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
}

.photography-image-wrapper {
  position: relative;
  height: 500px;
  padding: 0 75px;
  img {
    width: 100%;
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    bottom:-50px;
    height: 200px;
    width: calc(100%);
    background-color:$accent-color-bold;
    z-index: 1;
  }
}

.floating-header {
  text-align: center;
  z-index: 6;
  padding: 4rem 0;
  font-style: italic;
  text-transform: lowercase;
}

.photography-card-content {
  text-align: center;
}


// OLD

.pricing-block-header {
  height: 83%;
  padding-top: 70px;
}

.pricing-block-grid {
  position: relative;
  z-index: 1;
  padding: 0 6rem;
  width: 100%;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.pricing-block-outline {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 550px;
  vertical-align: middle;
  padding: 12px;
  background-color: $background-gray;
  border: 1px solid #e1e1de;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

.pricing-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2rem;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: $white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  & img {
    width: 80%;
  }
  & h3 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}

.pricing-lower-block {
  height: 375px;
  overflow: visible;
  z-index: 5;
  position: relative;
}

.pricing-dollar-value {
  color: #909090;
  font-style: italic;
  font-weight: 100;
  position: relative;
  line-height: 0.9em;
  height: 80px;
  font-size: 55px;
  &:before {
    content: "$";
    font-size: 38px;
    position: absolute;
    top: -7px;
    left: -15px;
  }
}

