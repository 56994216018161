$accent-color-bold: #bb9d99;
$accent-color-bold-hover: #c1a19d;
$accent-color-light: #d4afaa;
$white: #ffffff;
$gray-font: #4d504a;
$light-gray-font: #84847c;
$gray-border: #b7bfb1;
$background-gray: #f6f3f1;

//Navbar
$navbar-color: $white;
$navbar-size: 120px;
$navbar-padding: 15px;
//Fonts
$navbar-font: "Mulish", sans-serif;
$navbar-font-color: $gray-font;
$header-font: "Cormorant Garamond";
$header-font-color: $gray-font;
$body-font: "Mulish", sans-serif;
$body-font-color: $light-gray-font;
//Layout
$site-padding: 0 50px;
$block-height: 580px;
$block-padding: 5px;
//Footer
$footer-background-color: $accent-color-light;
