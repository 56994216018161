@import "variables";

$mobile-breakpoint: 700px;

.content-container {
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 1;
  transform: translateY(-100%);
}

.second-block-padding {
  padding: 10% 0 0 6%;
  @media (max-width: $mobile-breakpoint) {
    padding-bottom: 2rem;
  }
  @media (max-width: 600px) {
    padding-left: 3%;
  }
}

.window-resized-block {
  @media (min-width: 1400px) {
    height: 700px;
  }
}

.large-text-overlay {
  z-index: 10;
  width: 400px;
  transform: translate(100px, -80px);
  & > h1 {
    text-align: left;
    color: $white;
  }
  @media (max-width: 1150px) {
    transform: translate(50px, -100px);
  }
  @media (max-width: 900px) {
    display: none;
  }
}

.three-column-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  justify-items: center;
  height: 100%;
  grid-gap: 20px;
  @media (max-width: $mobile-breakpoint) {
    grid-template-columns: auto;
    grid-gap: 25px;
  }
  .col-sm {
    padding: 0;
    height: 440px;
    overflow: hidden;
    @media (max-width: 1200px) {
      height: 350px;
    }
    @media (max-width: 950px) {
      height: 325px;
    }
    @media (max-width: 800px) {
      height: 280px;
    }
    @media (max-width: $mobile-breakpoint) {
      height: 100%;
      width: 100%;
    }
  }
  .abby-jonathan-wedding {
    margin-top: -80px;
    @media (max-width: 1200px) {
      margin-top: -50px;
    }
    @media (max-width: 890px) {
      margin-top: 0px;
    }
  }
  .jesse-dress {
    margin-top: -90px;
    @media (max-width: 1200px) {
      margin-top: -60px;
    }
    @media (max-width: 950px) {
      margin-top: -50px;
    }
    @media (max-width: 890px) {
      margin-top: 0px;
    }
  }
}
.hidden-mobile {
  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.centered-text-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-text-overlay {
  width: 400px;
  color: $white;
  h3 {
    -webkit-font-smoothing: antialiased;
    list-style: none;
    -webkit-box-direction: normal;
    text-align: center;
    background: 0 0;
    border: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    font-size: 22px;
    line-height: 1.5em;
    letter-spacing: 0.15em;
    font-weight: 400;
    font-family: cormorant garamond, serif;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
  }
}

.banner-image {
  @media (min-width: 1400px) {
    margin-top: -50px;
  }
}

.about-container {
  padding: $site-padding;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
  @media (max-width: 950px) {
    grid-template-columns: 1.1fr 1fr;
  }
  @media (max-width: $mobile-breakpoint) {
    grid-template-columns: 1fr;
    .image-container {
      height: auto;
      margin-bottom: 2rem;
    }
  }
}

.wedding-party-pic {
  width: 100%;
}

.mitchell-family-pic {
  margin-top: 8%;
  @media (max-width: 1000px) {
    margin-top: 0;
  }
}

.maggie-headshot {
  @media (max-width: 650px) {
    height:200px
  }
}
