$mobile-breakpoint: 700px;

.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  @media (max-width: $mobile-breakpoint) {
    grid-auto-flow: dense;
    grid-template-columns: auto;
    .contact-left-column {
      order: 2;
    }
  }
}

.contact-image {
  max-width: 500px;
  margin-top:-160px;
  @media (max-width: 480px) {
    margin-top: -105px;
  }
}

.contact-left-column {
  padding: 2.5rem;
  padding-top: 5rem;
  width: 100%;
  position: relative;
  @media (max-width: 1000px) {
    padding: 2rem;
    padding-top: 5rem;
  }

  @media (max-width: 900px) {
    padding: 1rem;
    padding-top: 5rem;
  }

  @media (max-width: $mobile-breakpoint) {
    padding-top: 0;
    .image-container {
      justify-content: center;
    }
  }
}

.contact-right-column {
  width: 100%;
  max-width: 850px;
  padding: 3rem;
  padding-right: 8rem;

  @media (max-width: 1300px) {
    padding-right:6rem;
  }

  @media (max-width: 1000px) {
    padding: 2rem;
    padding-right:4rem;
  }

  @media (max-width: 900px) {
    padding: 1em;
  }
  @media (max-width: $mobile-breakpoint) {
    padding: 2rem;
    padding-top: 3rem;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  //@media (max-width: $mobile-breakpoint) {
    justify-content: center;
    align-items: center;
  //}
}

.text-input {
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;
  margin: 0 0 30px;
  padding: 0 2px;
  font-family: Mulish, sans-serif;
  font-size: 11px;
  font-weight: 500;
  background-color: transparent;
  outline: 0;
  box-sizing: border-box;
  transition: border-color 0.2s ease-in-out;
  cursor: auto;
}

.textarea-input {
  -webkit-font-smoothing: antialiased;
  display: block;
  position: relative;
  width: 100%;
  padding: 0 2px;
  font-family: Mulish, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: calc(26px - (0px - 2px));
  background-color: transparent;
  border-radius: 0;
  box-sizing: border-box;
  transition: border-color 0.2s ease-in-out;
  min-height: 100px;
}

.contact-form-input {
  letter-spacing: 0.1em;
  color: #323232;
  margin-top: 2rem;
  margin-bottom: 5px;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  &::placeholder {
    font-size: 11px;
    color: #72726a;
    letter-spacing: 0.3em;
    line-height: calc(26px - (0px - 2px));
    text-transform: uppercase;
  }
  &:focus,
  &:active {
    color: #1d1d1d;
    border-color: #84847c;
  }
  &.error {
    border-color: #db0000;
  }
}
.error-text {
  width: 100%;
  text-align: left;
  color: #db0000;
}

.primary-button {
  width: 180px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1rem;
  font-size: 16px;
  &:disabled {
    background: gray;
  }
}
