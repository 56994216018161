@import "variables.scss";

$mobile-breakpoint: 650px;

footer {
  width: 100%;
  background-color: $footer-background-color;
}

.footer-container {
  padding: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: $mobile-breakpoint) {
    padding: 2rem 20px;
  }
  & h3 {
    @media (max-width: 458px) {
      text-align: center;
      max-width: 19ch;
    }
  }
  & hr {
    border-color: #eadedd;
  }
  & a {
    font-size:18px;
    &:hover {
      color: white;
      text-decoration: none;
    }
    margin-bottom: 1rem;
  }
  & * {
    color: white;
  }
}

.footer-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

footer .row {
  height: 100%;
  .footer-block:last-child {
    border-right: none;
  }
  .footer-block:first-child {
    border-left: none;
  }
}

.footer-bottom-container {
  padding: 0 1%;
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    margin-top: 2rem;
  }
}

.footer-site-map {
  text-transform: uppercase;
  font-size:14px;
  a {
    font-size: 15px;
    padding: 0 3px;
  }
}

#footer-insta-feed {
  .instagram_gallery {
    @media (max-width: 1000px) {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(3, auto);
      a {
        width: 100% !important;
      }
    }
    @media (max-width: 600px) {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: repeat(2, auto);
      a {
        width: 100% !important;
      }
    }
  }
}
